import http from '@/utils/http';

/**
 * 产品分类管理
 */
export default {
  getList: {
    p: 'post,/attr/value/getlist',
    r: (data) => http({ url: '/attr/value/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/attr/value/add',
    r: (data) => http({ url: '/attr/value/add', method: 'post', data })
  },
  update: {
    p: 'post,/attr/value/update',
    r: (data) => http({ url: '/attr/value/update', method: 'post', data })
  },
  del: {
    p: 'post,/attr/value/delete',
    r: (data) => http({ url: '/attr/value/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/attr/value/getOne',
    r: (data) => http({ url: '/attr/value/getone', method: 'post', data })
  }
};
