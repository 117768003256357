import './list.scss';
import dataApi from '@/api/product/attrValue.js';

export default {
  name: 'project-attr-value',
  components: {},
  data() {
    return {
      drawerVisible: false,
      productAttrId: 0,
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchShow: false,
        searchShowBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            label: '名称',
            prop: 'name',
            type: 'input',
            search: 'true',
            required: true,
            span: 24,
            rules: [
              {
                required: true,
                message: '名称不能为空'
              }
            ]
          },
          {
            type: 'radio',
            label: '属性是否可选',
            dicData: [
              {
                label: '唯一',
                value: 0
              },
              {
                label: '单选',
                value: 1
              },
              {
                label: '多选',
                value: 2
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'select_type'
          },
          {
            type: 'radio',
            label: '属性类型',
            dicData: [
              {
                label: '规格',
                value: 0
              },
              {
                label: '参数',
                value: 1
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'type'
          },
          {
            type: 'radio',
            label: '商品属性关联',
            dicData: [
              {
                label: '不关联',
                value: 0
              },
              {
                label: '关联',
                value: 1
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'related_status',
            value: 0
          },
          {
            type: 'radio',
            label: '属性值录入方式',
            dicData: [
              {
                label: '手工录入',
                value: 0
              },
              {
                label: '从列表中选取',
                value: 1
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'input_type'
          },
          {
            type: 'array',
            label: '属性值可选值列表',
            span: 24,
            display: true,
            prop: 'input_list'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true,
            span: 12
          },
          {
            // 排序
            type: 'number',
            value: 0,
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting'
          },
          {
            label: '说明',
            prop: 'remark',
            type: 'textarea',
            span: 24
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      formDa: {},
      formOp: {
        column: [
          {
            type: 'input',
            label: '属性名称',
            span: 24,
            display: true,
            prop: 'name',
            required: true,
            rules: [
              {
                required: true,
                message: '属性名称必须填写'
              }
            ]
          },
          {
            label: '商品类型',
            prop: 'product_attr_id',
            type: 'select',
            addDisplay: true,
            editDisplay: true,
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_product_class`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            required: true,
            rules: [
              {
                required: true,
                message: '请选择商品类型'
              }
            ],
            span: 12
          },
          {
            type: 'radio',
            label: '分类筛选样式',
            dicData: [
              {
                label: '普通',
                value: 0
              },
              {
                label: '颜色',
                value: 1
              }
            ],
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'filter_type',
            value: 0,
            required: true,
            rules: [
              {
                required: true,
                message: '分类筛选样式必须填写'
              }
            ]
          },
          {
            type: 'radio',
            label: '能否进行检索',
            dicData: [
              {
                label: '不需要进行检索',
                value: 0
              },
              {
                label: '关键字检索',
                value: 1
              },
              {
                label: '范围检索',
                value: 2
              }
            ],
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'search_type',
            value: 0,
            required: true,
            rules: [
              {
                required: true,
                message: '能否进行检索必须填写'
              }
            ]
          },
          {
            type: 'radio',
            label: '属性是否可选:',
            dicData: [
              {
                label: '唯一',
                value: 0
              },
              {
                label: '单选',
                value: 1
              },
              {
                label: '多选',
                value: 2
              }
            ],
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'select_type',
            required: true,
            rules: [
              {
                required: true,
                message: '分类筛选样式必须填写'
              }
            ],
            dataType: 'number',
            value: 0
          },
          {
            type: 'radio',
            label: '商品属性关联',
            dicData: [
              {
                label: '不关联',
                value: 0
              },
              {
                label: '关联',
                value: 1
              }
            ],
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'related_status',
            required: true,
            rules: [
              {
                required: true,
                message: '商品属性关联必须填写'
              }
            ],
            value: 0
          },
          {
            type: 'radio',
            label: '属性值录入方式',
            dicData: [
              {
                label: '手工录入',
                value: 0
              },
              {
                label: '从列表中选取',
                value: 1
              }
            ],
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'input_type',
            value: 0,
            required: true,
            rules: [
              {
                required: true,
                message: '属性值录入方式必须填写'
              }
            ]
          },
          {
            type: 'array',
            label: '属性值可选值列表',
            span: 24,
            display: true,
            prop: 'input_list'
          },
          {
            type: 'radio',
            label: '是否支持手动新增',
            dicData: [
              {
                label: '是',
                value: 1
              },
              {
                label: '否',
                value: 0
              }
            ],
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'hand_add_status',
            required: true,
            rules: [
              {
                required: true,
                message: '是否支持手动新增必须填写'
              }
            ],
            value: 0
          },
          {
            type: 'number',
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting',
            title: '输入字段',
            value: 0
          },
          {
            type: 'radio',
            label: '属性类型',
            dicData: [
              {
                label: '规格',
                value: 0
              },
              {
                label: '参数',
                value: 1
              }
            ],
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'type',
            value: 0,
            required: true,
            rules: [
              {
                required: true,
                message: '属性类型必须填写'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            dicData: [
              {
                label: '启用',
                value: 1
              },
              {
                label: '禁用',
                value: -1
              }
            ],
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status',
            value: 1,
            required: true,
            rules: [
              {
                required: true,
                message: '状态必须填写'
              }
            ]
          }
        ],
        labelPosition: 'right',
        labelSuffix: '：',
        labelWidth: 150,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      },
      searchData: {}
    };
  },
  computed: {},
  created() {
    this.productAttrId = this.$route.params.id;
  },
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    addDrawerForm(row) {
      this.formDa = {};
      if (row.id !== undefined) {
        this.getInfo(row);
      }
      this.drawerVisible = true;
      this.formDaInit();

      this.formOp.disabled = false;
      this.formOp.submitBtn = true;
      this.formOp.emptyBtn = true;
    },
    drawerClose() {
      this.drawerVisible = false;
      this.$refs.addForm.resetForm();
      this.formDaInit();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit(form, done) {
      dataApi.add.r(form).then(() => {
        this.drawerClose();
        done(form);
        this.getList();
      });
      done();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 获取
    getList() {
      console.log(this.$route.params.id, this.formDa.product_attr_id);
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        product_attr_id: this.productAttrId,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    getInfo(row) {
      dataApi.getOne.r({ id: row.id }).then((res) => {
        console.log('接口返回', res);
        this.formDa = res.data;
      });
    },
    formDaInit() {
      this.formDa = {
        id: '',
        filter_type: 0,
        product_attr_id: parseInt(this.productAttrId, 10),
        search_type: 0,
        related_status: 0,
        input_type: 0,
        select_type: 0,
        hand_add_status: 0,
        status: 1,
        sorting: 0,
        type: 0
      };
    }
  }
};
