<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @row-save="handleSubmit"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="menuLeft">
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="small"
          @click.stop="addDrawerForm"
          >添加属性</el-button
        >
      </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
          icon="el-icon-edit-outline"
          :size="size"
          :type="type"
          @click.stop="addDrawerForm(row, index)"
          >编辑</el-button
        >

        <el-button
          icon="el-icon-delete"
          :size="size"
          :type="type"
          @click.stop="rowDel(row, index)"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <el-drawer
      size="50%"
      title="添加/编辑属性"
      :before-close="drawerClose"
      :visible.sync="drawerVisible"
      direction="rtl"
    >
      <avue-form
        ref="addForm"
        :option="formOp"
        @submit="handleSubmit"
        v-model="formDa"
      >
      </avue-form>
    </el-drawer>
  </div>
</template>
<script>
import Value from './value';

export default Value;
</script>
